/*
Sitecore CDP frontend JavaScript integration with backend WebAPIs
*/

export default class {
    static getBrowserId() {
        var url = "/api/event/getBrowserId";

        return new Promise((resolve, reject) => (fetch(url, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return resolve(response.json());
        }).catch((error) => {
            console.log(error);
            return reject(error);
        })));
    }

    static createIdentityEvent(browserId, pageUrl, id, email, firstname, lastname) {
        var url = "/api/event/createIdentity?browserId=" + browserId + "&pageUrl=" + pageUrl + "&id=" + id + "&email=" + email + "&firstname=" + firstname + "&lastname=" + lastname;

        return new Promise((resolve, reject) => (fetch(url, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return resolve(response.json());
        }).catch((error) => {
            console.log(error);
            return reject(error);
        })));
    }

    static createAddCartEvent(browserId, pageUrl, referenceId, type, name, productId, item_id) {
        var url = "/api/event/createAddCart?browserId=" + browserId + "&pageUrl=" + pageUrl + "&referenceId=" + referenceId + "&type=" + type + "&name=" + name + "&productId=" + productId + "&item_id=" + item_id;

        return new Promise((resolve, reject) => (fetch(url, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return resolve(response.json());
        }).catch((error) => {
            console.log(error);
            return reject(error);
        })));
    }

    static deleteGuest(guestRef) {
        var url = "/api/guest/delete?guestRef=" + guestRef;

        return new Promise((resolve, reject) => (fetch(url, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return resolve(response.json());
        }).catch((error) => {
            console.log(error);
            return reject(error);
        })));
    }

    static retrieveGuest(email) {
        let url = "/api/guest/retrieve?email=" + email;

        return new Promise((resolve, reject) => (fetch(url, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return resolve(response.json());
        }).catch((error) => {
            console.log(error);
            return reject(error);
        })));
    }

    static updateGuest(guestRef, city, state, country, gender) {
        var url = "/api/guest/update?guestRef=" + guestRef + "&city=" + city + "&state=" + state + "&country=" + country + "&gender=" + gender;

        return new Promise((resolve, reject) => (fetch(url, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return resolve(response.json());
        }).catch((error) => {
            console.log(error);
            return reject(error);
        })));
    }

    static saveGuestData(guestRef, language, planName, state, county, lastStep) {
        var url = "/api/guest/savedata?guestRef=" + guestRef + "&language=" + language + "&planName=" + planName + "&state=" + state + "&county=" + county + "&lastStep=" + lastStep;

        return new Promise((resolve, reject) => (fetch(url, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return resolve(response.json());
        }).catch((error) => {
            console.log(error);
            return reject(error);
        })));
    }

    static trackMeta(browserId, eventName) {
        var url = "/api/meta/track?browserId=" + browserId + "&eventName=" + eventName + "&url=" + window.location.pathname;

        return new Promise((resolve, reject) => (fetch(url, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return resolve(response.json());
        }).catch((error) => {
            console.log(error);
            return reject(error);
        })));
    }
}
