/*
 * This file is main entry point for CDP script
 */
define("entry-cdp", [
    "@modules/cdp/index"
], function (CDP) {
    $(function () {
        window.CDP = CDP;
        setTimeout(function () {
            console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
            console.log("CDP file");
            console.log("                                             " + new Date());
            console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
        }, 1500);

        // Get BrowserId before calling any CDP events
        CDP.getBrowserId().then(data => {
            let browserId = data;
            console.log("CDP browserId:" + browserId);
            $(".header .tty-wrapper a[href='tel:18774525898'], .header .tty-wrapper a[href='tel:8774525898']").on("click", function () {
                // Clicked click-to-call phone number - Pass Contact event to Meta
                CDP.trackMeta(browserId, "Contact").then(data => {
                    console.log(data);
                });
            });
        });
    });
});
